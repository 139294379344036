/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "gatsby";

const MyStyledLink = (props) => {
  return (
    <div
      sx={{
        height: "100%",
        width: "100%",
        maxHeight: "3rem",
        display: "inline-block",
      }}
    >
      <Link
        to={props.link}
        sx={{ variant: props.styling }}
        activeClassName="active"
        partiallyActive={true}
      >
        <span
          sx={{
            display: "table-cell",
            verticalAlign: "middle",
          }}
        >
          {props.text}
        </span>
      </Link>
    </div>
  );
};

export default MyStyledLink;
