/** @jsx jsx */
import { jsx } from "theme-ui";

const Footer = () => {
  return (
    <div
      sx={{
        width: "100%",
        height: "3rem",
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: "row",
          margin: "auto",
          justifyContent: "center",
          alignItems: "center",
          height: "3rem",
          textAlign: "center",
        }}
      >
        <div sx={{ fontWeight: "medium", fontSize: "xs" }}>Chase Fenske</div> <div sx={{ fontWeight: "normal", fontSize: "xs" }}>&copy;</div>
      </div>
    </div>
  );
};

export default Footer;
