/** @jsx jsx */
import { jsx } from "theme-ui";
//import { useState } from "react";
import { useState, useRef } from "react";
import { useColorMode } from "theme-ui";
import { BsCircleHalf } from "react-icons/bs";
import { HiMenu } from "react-icons/hi";
import { MdClose } from "react-icons/md";
import MyStyledLink from "./MyStyledLink";
import { keyframes } from "@emotion/react";
import * as CONSTS from "../consts/consts";
import Icon from "../images/svg/logo.svg";
import { Link } from "gatsby";

const Header = () => {
  const [colorMode, setColorMode] = useColorMode();
  const [menuOpen, setMenuOpen] = useState(false);
  const [navHidden, setNavHidden] = useState(true);
  const nav = useRef(null);

  const fadeIn = keyframes({ from: { left: "100vw" }, to: { left: "25vw" } });
  const fadeOut = keyframes({ from: { left: "25vw" }, to: { left: "100vw" } });
  const fadeIT = keyframes({ from: { left: "100vw" }, to: { left: "50vw" } });
  const fadeOT = keyframes({ from: { left: "50vw" }, to: { left: "100vw" } });

  return (
    <div>
      <header
        sx={{
          backgroundColor: "secondary",
          width: "100vw",
          maxHeight: "3rem",
          height: "100%",
          overflow: "hidden",
          position: "fixed",
          zIndex: "8",
          left: 0,
          top: 0,
          flex: "1 1 auto",
        }}
      >
        <div
          sx={{
            m: "auto",
            display: "flex",
            px: "1.3rem",
            py: ".1rem",
            maxWidth: "110em",
            alignItems: "center",
            width: "100vw",
            height: "100%",
            maxHeight: "3rem",
          }}
        >
          <div
            sx={{
              height: "100%",
              width: "100%",
              maxWidth: "5rem",
              maxHeight: "3rem",
              display: "flex",
            }}
          >
            <Link
              to="/"
              sx={{
                m: "auto",
                p: ".2rem",
                px: ".5rem",
                height: "100%",
                width: "100%",
                maxHeight: "100%",
                maxWidth: "4.8rem",
                color: "inherit",
                borderRadius: "10px",
                "&:hover": {
                  backgroundColor: "highlighted",
                },
                "&.active": {
                  backgroundColor: "highlighted",
                },
              }}
            >
              <Icon />
            </Link>
          </div>
          {/* <MyStyledLink text="Hello" link="/" styling={"styledLinks.primary"} /> */}
          <div sx={{ mx: "auto" }} />
          <div
            sx={{
              visibility: ["hidden", "hidden", "visible"],
              maxWidth: ["0", "0", "100%"],
              maxHeight: "3em",
              height: "100%",
              gap: ".1rem",
              my: "auto",
              display: "flex",
              flexDirection: "row",
            }}
          >
            {CONSTS.links.map((link) => (
              <MyStyledLink
                text={link.split("/")[0]}
                link={"/" + link.split("/")[1]}
                styling={"styledLinks.primary"}
                key={link}
              />
            ))}
          </div>
          <div
            sx={{
              display: "flex",
              flexDirection: "row",
              height: "100%",
              maxHeight: "3rem",
              marginRight: ".1rem",
              gap: ".1rem",
            }}
          >
            <div
              sx={{
                visibility: ["visible", "visible", "hidden"],
                maxWidth: ["3rem", "3rem", "0"],
                maxHeight: ["3rem", "3rem", "0"],
                height: "100%",
                width: "100%",
                display: "inline-block",
              }}
              onClick={(e) => {
                setMenuOpen(!menuOpen);
                setNavHidden(false);
                if (!nav.current) return;
                const navLink = nav.current.querySelector("a");
                if (navLink) navLink.focus();
              }}
            >
              <HiMenu
                sx={{
                  variant: "iconButton.primary",
                }}
              />
            </div>
            <div
              sx={{
                height: "100%",
                width: "100%",
                maxHeight: "3rem",
                maxWidth: "3rem",
                display: "inline-block",
              }}
              onClick={(e) => {
                const index = CONSTS.colors.indexOf(colorMode);
                const next = CONSTS.colors[(index + 1) % CONSTS.colors.length];
                setColorMode(next);
              }}
            >
              <BsCircleHalf
                sx={{
                  variant: "iconButton.primary",
                }}
              />
            </div>
          </div>
        </div>
      </header>
      <div>
        <div
          sx={{
            zIndex: "9",
            width: [
              `${!menuOpen ? "0vw" : "25vw"}`,
              `${!menuOpen ? "0vw" : "50vw"}`,
            ],
            height: "100vh",
            position: "fixed",
            top: "0",
            left: "0",
          }}
          onClick={(e) => {
            setMenuOpen(!menuOpen);
          }}
        />
        <div
          sx={{
            position: "fixed",
            left: "200vw",
            top: "0",
            width: ["75vw", "50vw"],
            height: "100vh",
            paddingTop: ".4em",
            backgroundColor: "primary",
            display: "flex",
            flexDirection: "column",
            visibility: `${navHidden ? "hidden" : "visible"}`,
            zIndex: "10",
            animation: [
              `${menuOpen ? fadeIn : fadeOut} .5s ease 1 normal forwards`,
              `${menuOpen ? fadeIT : fadeOT} .5s ease 1 normal forwards`,
              "",
            ],
          }}
        >
          <div
            sx={{
              height: "2.5em",
              display: "flex",
              marginBottom: "1em",
            }}
          >
            <div
              sx={{
                margin: "auto",
              }}
            />
            <div
              sx={{
                px: "5.5em",
              }}
              onClick={(e) => {
                setMenuOpen(!menuOpen);
                setNavHidden(false);
              }}
            >
              <MdClose sx={{ variant: "iconButton.secondary" }} />
            </div>
          </div>
          <div
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              paddingRight: ["1rem", "2rem"],
              width: "100%",
            }}
          >
            {CONSTS.links.map((link) => (
              <MyStyledLink
                text={link.split("/")[0]}
                link={"/" + link.split("/")[1]}
                styling={"styledLinks.secondary"}
                key={link}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
