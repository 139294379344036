/** @jsx jsx */
import { jsx } from "theme-ui";
import Header from "./Header";
import { ThemeProvider } from "theme-ui";
import theme from "../gatsby-plugin-theme-ui/index.js";
import Prism from "@theme-ui/prism";
import Footer from "./Footer";

const components = {
  pre: ({ children }) => <>{children}</>,
  code: Prism,
};

const Layout = (props) => {
  return (
    <ThemeProvider theme={theme} components={components}>
      <div
        sx={{
          fontFamily: "body",
          fontWeight: "normal",
          display: "flex",
          flex: 1,
          flexDirection: "column",
          overflowX: "hidden",
          maxWidth: "100vw",
          height: "100%",
          minHeight: "100vh",
          paddingTop: "3rem"
        }}
      >
        <Header />
        <div
          sx={{
            variant: props.styling,
            ...props.additionalStyles
          }}
        >
          {props.children}
        </div>
        <Footer />
      </div>
    </ThemeProvider>
  );
};

export default Layout;
