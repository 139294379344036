export const links = [
    "About/about",
    "Blog/blog",
    "Projects/projects",
    "Contact/contact"
]
export const colors = [
    "light",
    "dark",
    "deep",
    "chipmunkpink",
    "blue",
    "orangejulius",
  ];